.profile-page .section-title {
  font-size: 22px;
  color: black;
  margin-bottom: 4px;
}
@media screen and (max-width: 767px) {
  .profile-page .section-title {
    margin-top: 15px;
    margin-bottom: 0;
  }
}
.profile-page .profile-form {
  margin-top: 15px;
}
.profile-page .nav-pills {
  border-bottom: 1px solid #152850;
}
.profile-page .nav-pills .nav-link.active, .profile-page .nav-pills .nav-pills .show > .nav-link {
  background-color: #152850;
}