.auth-page .banner-wrapper {
  padding: 0;
}
.auth-page .login-container {
  /*background-color: $creid-dark-blue;*/
  height: 85vh;
  border-top: 3px solid #292661;
}
.auth-page .auth-page-content {
  padding: 40px 15px;
  margin: 5em 0;
  border-radius: 20px;
  border: 3px solid #292661;
  background-color: white;
}
.auth-page .auth-page-content h4 {
  font-size: 1.5rem;
  color: #292661;
}
@media screen and (min-width: 768px) {
  .auth-page .auth-page-content h4 {
    font-size: 1.5rem;
  }
}
.auth-page .auth-page-content p {
  margin: 20px 0;
}
.auth-page .auth-page-content form .form-control.input-validation-error {
  margin-bottom: 0;
}
.auth-page .auth-page-content a {
  text-decoration: underline;
}
.auth-page .text-content {
  padding-top: 20px;
}
.auth-page footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #292661;
  color: white;
  text-align: center;
}
.auth-page footer p {
  margin: 10px 0 10px 0;
}