@media screen and (max-width: 767px) {
  .document-library-page .section-btn-tabs {
    display: none;
  }
}
.document-library-page .section-btn-tabs button {
  padding: 10px 20px;
  border: 1px solid black;
  color: #292661;
  background-color: white;
}
.document-library-page .section-btn-tabs button.active-tab {
  font-weight: bold;
  color: white;
  background-color: #292661;
}
.document-library-page .document-section {
  border-bottom: 1px solid lightgrey;
}
.document-library-page .card {
  border: 2px solid #292661;
}
.document-library-page .card .btn-link {
  color: #292661;
}
.document-library-page .card .active-files {
  font-weight: bold;
}
.document-library-page .card .card-header {
  margin-bottom: -0.25rem;
}
.document-library-page .card h5 {
  color: #292661;
}
.document-library-page .card .inactive-section {
  display: none;
}
.document-library-page .inactive-section {
  display: none;
}