.banner-wrapper {
  background-image: url("/images/stock2.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 12rem 0;
  position: relative;
}
.banner-wrapper .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  top: 0;
  left: 0;
  background-color: #000;
}
.banner-wrapper h1 {
  font-size: 4rem;
  font-weight: 900;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .banner-wrapper h1 {
    font-size: 5.5rem;
  }
}
.banner-wrapper h3 {
  font-size: 2rem;
  font-weight: 800;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .banner-wrapper h3 {
    font-size: 2.5rem;
  }
}
.banner-wrapper p {
  color: #fff;
}
.banner-wrapper p.dark {
  color: #292661;
}