header {
  -webkit-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  position: relative;
}

.header-nph .navbar-default {
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}
.header-nph .navbar-brand {
  padding: 15px 0 15px 0;
}
.header-nph .navbar-brand img {
  max-height: 80px;
  height: 55px;
}
.header-nph .navbar-brand img.login-page {
  height: 75px;
}
@media screen and (min-width: 992px) {
  .header-nph .navbar-brand img.login-page {
    height: 100px;
  }
}
.header-nph .menu_area {
  background-color: #292661;
  border-top: 1px solid #221621;
  border-bottom: 1px solid #221621;
  /* nav-bar */
}
.header-nph .menu_area .navbar {
  padding: 0;
}
.header-nph .menu_area .navbar > ul > li.current > a:after {
  border-color: transparent #5085a5 #5085a5 transparent;
}
.header-nph .menu_area .navbar-nav {
  /* nav-item */
}
.header-nph .menu_area .navbar-nav .nav-item:not(:last-child) {
  margin-right: 5px;
}
@media screen and (max-width: 991px) {
  .header-nph .menu_area .navbar-nav .nav-item:not(:last-child) {
    border-bottom: 1px solid #e6d9e4;
  }
  .header-nph .menu_area .navbar-nav .nav-item:not(:last-child).show {
    border-bottom: none;
  }
}
.header-nph .menu_area .navbar-nav .nav-item .nav-link {
  display: inline-block;
  font-size: 13px;
  color: white;
  font-weight: 600;
  padding: 21px 0.6rem;
  letter-spacing: 0.45px;
  text-transform: uppercase;
  text-decoration: none;
}
.header-nph .menu_area .navbar-nav .nav-item .nav-link.dropdown-toggle {
  padding-right: 0.2em;
}
.header-nph .menu_area .navbar-nav .nav-item .nav-link:hover, .header-nph .menu_area .navbar-nav .nav-item .nav-link:focus {
  color: white;
  text-decoration: underline;
}
.header-nph .menu_area .navbar-nav .nav-item .nav-link:hover.dropdown-toggle, .header-nph .menu_area .navbar-nav .nav-item .nav-link:focus.dropdown-toggle {
  text-decoration: none;
}
.header-nph .menu_area .navbar-nav .nav-item .nav-link:hover.dropdown-toggle:focus, .header-nph .menu_area .navbar-nav .nav-item .nav-link:focus.dropdown-toggle:focus {
  text-decoration: underline;
}
@media screen and (max-width: 991px) {
  .header-nph .menu_area .navbar-nav .nav-item .nav-link {
    padding: 10px 0.5rem;
    display: block;
  }
}
.header-nph .menu_area .navbar-nav .nav-item .dropdown-toggle {
  padding-right: 0.6rem;
}
.header-nph .menu_area .navbar-nav .nav-item .dropdown-toggle::after {
  content: none;
  display: none;
}
.header-nph .menu_area .navbar-nav .nav-item .dropdown-toggle .nav-link {
  padding-right: 2px;
}
.header-nph .menu_area .navbar-nav .nav-item .dropdown-indicator {
  display: inline-block;
  color: #e6d9e4;
  padding-right: 0.6em;
}
.header-nph .menu_area .navbar-nav .nav-item .dropdown-indicator .open-icon {
  font-size: 11px;
}
@media screen and (max-width: 991px) {
  .header-nph .menu_area .navbar-nav .nav-item .dropdown-indicator {
    position: absolute;
    right: 12px;
    top: 12px;
    padding-right: 0;
  }
  .header-nph .menu_area .navbar-nav .nav-item .dropdown-indicator .open-icon {
    font-size: 18px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
  }
}
@media screen and (max-width: 991px) {
  .header-nph .menu_area .navbar-nav .nav-item.show .dropdown-indicator .open-icon {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
.header-nph .menu_area .navbar-nav .nav-item .dropdown-menu {
  background-color: white;
  padding: 15px 0 0 0;
  margin-top: 0;
  -webkit-box-shadow: 0 0 0 2px transparent;
          box-shadow: 0 0 0 2px transparent;
}
@media screen and (max-width: 991px) {
  .header-nph .menu_area .navbar-nav .nav-item .dropdown-menu {
    position: relative;
  }
}
.header-nph .menu_area .navbar-nav .nav-item .dropdown-menu .dropdown-item {
  color: #292661;
  font-size: 13px;
  text-decoration: none;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  margin-bottom: 12px;
  padding: 0 20px;
}
.header-nph .menu_area .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .header-nph .menu_area .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus {
  text-decoration: underline;
}
.header-nph .menu_area .navbar-nav li.current > a, .header-nph .menu_area .navbar-nav li.active > a {
  color: white;
}
@media screen and (min-width: 992px) {
  .header-nph .menu_area .navbar-nav .dropdown .dropdown-menu {
    position: absolute;
    top: 55px;
    display: block;
    visibility: hidden;
    opacity: 0;
  }
  .header-nph .menu_area .navbar-nav .dropdown .dropdown-menu.fade-up {
    top: 120%;
  }
  .header-nph .menu_area .navbar-nav .dropdown .dropdown-menu.fade-down {
    top: 80%;
    -webkit-transform: rotateX(-75deg);
            transform: rotateX(-75deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  .header-nph .menu_area .navbar-nav .dropdown .dropdown-menu.show {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    opacity: 1;
    visibility: visible;
    top: 55px;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
  .header-nph .menu_area .navbar-nav .dropdown:hover .dropdown-menu {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    opacity: 1;
    visibility: visible;
    top: 55px;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}
@media screen and (max-width: 991px) {
  .header-nph .menu_area .navbar-toggler {
    background: white !important;
    width: 45px;
    height: 40px;
    padding: 0;
    border: 1px solid #232323;
    border-color: #232323 !important;
    border-radius: 4px;
    position: absolute;
    right: 0;
    top: -50px;
    cursor: pointer;
    z-index: 12399994;
  }
  .header-nph .menu_area .navbar-toggler .toggler-icon {
    color: #232323;
  }
}
.header-nph .navbar-toggler {
  visibility: hidden;
}
@media screen and (max-width: 991px) {
  .header-nph .navbar-toggler {
    background: white !important;
    width: 45px;
    height: 40px;
    padding: 0;
    border: 1px solid #232323;
    border-color: #232323 !important;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    visibility: visible;
  }
  .header-nph .navbar-toggler .toggler-icon {
    color: #232323;
  }
}