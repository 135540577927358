.scroll-to-top {
  font-size: 20px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  visibility: hidden;
  opacity: 0;
  border-radius: 50%;
  background-color: #719500;
  border: 2px solid #719500;
  width: 35px;
  height: 35px;
  line-height: 30px;
  z-index: 9999;
  outline: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.scroll-to-top i {
  color: #152850;
}
.scroll-to-top:hover, .scroll-to-top:focus {
  color: white;
  background-color: #4a6200;
  border-color: #4a6200;
}
.scroll-to-top:hover i, .scroll-to-top:hover .icon, .scroll-to-top:focus i, .scroll-to-top:focus .icon {
  color: white;
}
.scroll-to-top:hover:visited, .scroll-to-top:focus:visited {
  color: #fff;
  text-decoration: none;
}
.scroll-to-top .icon {
  color: #fff;
  width: 90%;
  height: 93%;
}
.scroll-to-top.show {
  visibility: visible;
  opacity: 1;
}